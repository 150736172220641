import { useEffect, useState } from 'react';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import Divider from '../../Atoms/Divider/Divider';
import { H1, H2 } from '../../Atoms/Typography/Headings/Heading';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';
import OrderDetailsModel from '../../Order/Models/OrderDetailsModel.interface';
import { AddOrderLinesToCart, GetOrder } from '../../OrderComponent/Order';
import OrderRowListing from '../../OrderComponent/OrderRowListing';
import { getUrlParameter } from '../../Shared/Common/Helpers';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import { styled } from '../../stitches.config';

function OrderDetailsPage() {
  const {
    staticPages: { recurringOrdersPage, cartPage },
    languageRoute,
  } = useAppSettingsData();

  const {
    'orderDetailsPage/orderLines': orderLinesLabel,
    'orderDetailsPage/addAllOrderLines': addAllOrderLinesLabel,
    'orderDetailsPage/subsribe': subscribeLabel,
  } = useTranslationData();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const kexNavigate = useKexNavigate();

  const { customerId } = useUserStateData();

  const { channelId } = useCurrentPage<PageModelBase>();

  var query = getUrlParameter('orderId');
  const url = `/api/${languageRoute || 'en'}/order/GetOrder?orderId=${query}`;
  const [order, setOrder] = useState<any>();

  useEffect(() => {
    customerId &&
      GetOrder(channelId, url, setIsLoading).then((data: OrderDetailsModel) => {
        if (data) {
          setOrder(data);
        }
      });
  }, [channelId]);

  const handleAddAllOrderItems = async () => {
    await AddOrderLinesToCart(
      channelId,
      languageRoute,
      order.externalOrderID,
      setIsAdding
    );
    kexNavigate(cartPage);
  };

  const handleSubscribe = async () => {
    await AddOrderLinesToCart(
      channelId,
      languageRoute,
      order.externalOrderID,
      setIsAdding
    );
    kexNavigate(recurringOrdersPage);
  };

  return (
    <main>
      {!isLoading && order && (
        <div>
          <SectionsWrapper>
            <TopContent>
              <StyledH1>{order.externalOrderID}</StyledH1>
            </TopContent>
          </SectionsWrapper>
          <OrdersSection>
            <HeadingSection>
              <SubHeading>{orderLinesLabel}</SubHeading>
              <MinDividerMargin />
              <CartSection>
                <div>
                  <OrderRowListing order={order} />
                </div>
                <ButtonsContainer>
                  <AddAllButton
                    onClick={handleAddAllOrderItems}
                    isLoading={isAdding}
                  >
                    {addAllOrderLinesLabel}
                  </AddAllButton>
                  <SubscribeButton
                    onClick={handleSubscribe}
                    isLoading={isAdding}
                    type={'secondary'}
                  >
                    {subscribeLabel}
                  </SubscribeButton>
                </ButtonsContainer>
              </CartSection>
            </HeadingSection>
          </OrdersSection>
        </div>
      )}
    </main>
  );
}

const AddAllButton = styled(CtaButton, {
  mb: 4,
  letterSpacing: 0,
});

const SubscribeButton = styled(CtaButton, {
  px: 2,
  color: '$black',
  letterSpacing: 0,
});

const ButtonsContainer = styled('div', {
  '@mediaMaxLarge': {
    mt: 4,
  },
});

const PaddingStyle = {
  '@mediaMinLarge': {
    py: 7.5,
    px: 30,
  },
  '@mediaMinGreat': {
    px: 60,
  },
};

const HeadingSection = styled('div', {
  maxW: 256,
  '@mediaMaxLarge': {
    pl: 0.25,
  },
  '@mediaMinLarge': {
    mx: 'auto',
  },
});

const CartSection = styled('div', {
  display: 'grid',
  gg: 4,
  gridTemplateColumns: '1fr',
  '@mediaMinLarge': {
    gridTemplateColumns: '6.5fr 3.5fr',
    gg: 8,
  },
});

const MinDividerMargin = styled(Divider, {
  w: 16,
  my: 4,
  '@mediaMinLarge': {
    mb: 9,
  },
  '@mediaMaxSmall': {
    my: 4,
    mb: 4,
  },
});

const SectionsWrapper = styled('div', {
  w: '100%',
  p: 7.5,
  backgroundColor: '$white',
  ...PaddingStyle,
});

const TopContent = styled('div', {
  maxW: 256,
  display: 'flex',
  flexDirection: 'column',
  '@mediaMinLarge': {
    mx: 'auto',
  },
});

const OrdersSection = styled('div', {
  w: '100%',
  p: 7.5,
  ...PaddingStyle,
});

const StyledH1 = styled(H1, {
  fs: 13,
  fontWeight: '$fw300',
  '@mediaMinLarge': {
    fs: 24,
    lineHeight: '62px',
  },
});

const SubHeading = styled(H2, {
  fontWeight: '$fw300',
  fs: 13,
  lineHeight: '31px',
});

export default OrderDetailsPage;
